import { useAuth } from "src/firebase/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setUser } from "src/stores/auth";
import Skeleton from "./Skeleton";
import LoginPage from "src/pages/Index";
import Error from "./Error";
import Notification from "./Notification";

export const PrivateRoute = () => {
  const { error, loading, user } = useAuth();
  const setUsers = setUser((v) => v.setUser);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (user) {
      setUsers();

      if (pathname === "/") navigate("/dashboard");
      if (search) navigate(`${pathname}${search}`);
    }
  }, [user]);

  if (error) return <Error error={error.message} />;
  if (loading)
    return (
      <div className="flex flex-col gap-5 lg:px-80 lg:py-6">
        <Skeleton />
      </div>
    );

  return (
    <>
      {user ? (
        <main>
          <Notification />
          <Outlet />
        </main>
      ) : (
        <LoginPage />
      )}
    </>
  );
};

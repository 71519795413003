import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import requestPermission, { onMessageListener } from "src/firebase";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () =>
    toast(<ToastDisplay />, {
      style: {
        backgroundColor: "#344C64",
      },
    });

  function ToastDisplay() {
    return (
      <div className="flex flex-col gap-2 text-white">
        <p className="font-bold text-lg">{notification?.title}</p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title ?? "",
        body: payload?.notification?.body ?? "",
      });
    })
    .catch((err) => console.log("failed: ", err));

  return <div />;
};

export default Notification;

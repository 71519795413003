import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import "firebase/compat/firestore";
import {
  getToken,
  MessagePayload,
  onMessage,
  getMessaging,
} from "firebase/messaging";
import { getStorage } from "firebase/storage";

const firebaseConfigDev = {
  apiKey: "AIzaSyCpgMv7dGnkWnHxN5PNGlKuCjH9o6O0XKw",
  authDomain: "development-pilipilih.firebaseapp.com",
  projectId: "development-pilipilih",
  storageBucket: "development-pilipilih.appspot.com",
  messagingSenderId: "434614842838",
  appId: "1:434614842838:web:e06f88c86d4acc305fa077",
  measurementId: "G-YYSTL6LGRK",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyAu0Lu9mgnTzPyTBdZ_HEMRPgzrJxEqYoM",
  authDomain: "prod-pilipilih.firebaseapp.com",
  projectId: "prod-pilipilih",
  storageBucket: "prod-pilipilih.appspot.com",
  messagingSenderId: "817039803709",
  appId: "1:817039803709:web:45e1ef162ef5a07ce4b154",
  measurementId: "G-MNGHF8PVHJ",
};

if (!firebase.apps.length) {
  firebase.initializeApp(
    import.meta.env.MODE === "production"
      ? firebaseConfigProd
      : firebaseConfigDev
  );
}

const FbApp = firebase.app();
const FbAuth = firebase.auth();
const FbStorage = getStorage(firebase.app());
const FbFirestore = FbApp.firestore();
const messaging = getMessaging(FbApp);

export { FbApp, FbAuth, messaging, FbStorage, FbFirestore };

export const requestForToken = (): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    getToken(messaging, {
      vapidKey: import.meta.env.VITE_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_VAPID_KEY,
      });
      return token;
      // if (token) {
      //   console.log("Token received: ", token);
      // } else {
      //   console.log(
      //     "No registration token available. Request permission to generate one."
      //   );
      // }
    } else {
      console.log("Unable to get permission to notify.");
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
};

export default requestPermission;

export const onMessageListener = () =>
  new Promise<MessagePayload>((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload.data);
      resolve(payload);
    });
  });

import cx from "classnames";

interface IError {
  error: string;
  className?: string;
}

const Error = ({ error, className }: IError) => {
  return (
    <div className={cx("font-semibold text-base text-red-500", className)}>
      Error: {error}
    </div>
  );
};

export default Error;
